<template>
  <div id="app">
    <nav>
      <router-link to="/">Home</router-link> |
      <!-- <router-link to="/about">About</router-link> | -->
      <!--<router-link to="/TextProcessor">TextProcessor</router-link> |-->
      <!--<router-link to="/TextProcessor2">TextProcessor2</router-link> |-->
      <!-- <router-link to="/pictureProcessor">pictureProcessor</router-link> | -->
      <!-- <router-link to="/storyProcessor">storyProcessor</router-link> | -->
      <!-- <router-link to="/razProcessor">razProcessor</router-link> | -->
      <!-- <router-link to="/hnmProcessor">hnmProcessor</router-link> | -->
      <!-- <router-link to="/addMp3Time">AddMp3Time</router-link> | -->
      <!-- <router-link to="/editTime">EditTime</router-link> | -->
      <!-- <router-link to="/teenTime">teenTime</router-link> | -->
      <router-link to="/hnmTime">hnmTime</router-link> |
      <!-- <router-link to="/addRaz">addRaz</router-link> | -->
    </nav>
    <router-view/>
  </div>
</template>