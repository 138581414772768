<!-- 修改这个类别 43行：修改这个值表达是哪一个类别 -->
<template>
    <div class="container">
      <!-- 新增的文本框及按钮 -->
      <div class="input-area-container">
        <input v-model="lessonNum" class="input-field" placeholder="Lesson Number">
        <textarea v-model="courseData" class="input-text" placeholder="Enter the courses data" rows="10"></textarea>
        <input v-model="level" class="input-field" placeholder="Level (e.g., D, E)">
        <button @click="processData" class="action-button">Process Data</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        lessonNum: '', // 初始值
        courseData: '', // 课程数据
        level: '', // 级别（例如 D 或 E）
      };
    },
    methods: {
      processData() {
        // 将课程数据分解成行
        const lines = this.courseData.split('\n');
        let courseNumber = null;
        const processedData = [];
        let initialValue = parseInt(this.lessonNum, 10); // 记录初始值
  
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i].trim();
          // 假设数字行和文本行交替出现
          if (i % 2 === 0) {
            courseNumber = parseInt(line, 10); // 课程编号
          } else {
            const courseTitle = line; // 课程标题
            const record = {
              lesson: `Leveled ${this.level} ${courseNumber}`,
              subject: courseTitle,
              lessonNum: initialValue,
              lessonPic: `zhiwangen/RazBook/RazPic/${this.level}/${this.level}-${courseNumber}.jpg`,
              kind_desc: 5,   // 这里要修改，或者清空
            };
            processedData.push(record);
            initialValue += 1; // 递增初始值
          }
        }
  
        // 发送HTTP POST请求到后端接口
        fetch('https://chat.morecale.com/zhiwangen/addRaz.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(processedData),
        })
          .then(response => response.json())
          .then(data => {
            // 处理后端接口的响应
            console.log('Response from server:', data);
            if (data.success) { // 假设后端返回的数据中有个success标识操作是否成功
              alert('Data processed successfully!');
              this.lessonNum = String(initialValue); // 更新初始值为最后的值
              this.courseData = ''; // 清空课程数据
            } else {
            alert(data.message);
                this.lessonNum = ''; // 清空课程编号
                this.courseData = ''; // 清空课程数据
                this.level = ''; // 清空级别
            }
          })
          .catch(error => {
            // 处理请求过程中的错误
            console.error('Error:', error);
            alert('Error processing data. Please try again.');
          });
      },
    },
  };
  </script>
  
  <style scoped>
  /* 根据实际需要调整样式 */
  .input-area-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .input-field, .input-text {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-text {
    width: 600px; /* 调整宽度 */
    height: 200px; /* 调整高度 */
  }
  
  .action-button {
    width: 120px; /* 调整按钮宽度 */
    height: 40px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  /* 媒体查询 */
  @media (max-width: 768px) { /* 针对小于768px宽的屏幕 */
    .input-text {
      width: 90%; /* 宽度调整为父容器宽度的90% */
      height: 150px; /* 高度适当调整 */
    }
  }
  
  @media (max-width: 480px) { /* 针对小于480px宽的屏幕 */
    .input-text {
      width: 90%; /* 宽度进一步调整为父容器宽度的90% */
      height: 100px; /* 高度进一步调整 */
    }
  }
  </style>