
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AboutPage from '../views/AboutPage.vue';
import TextProcessor from '../views/TextProcessor.vue';
import TextProcessor2 from '../views/TextProcessor2.vue';
import pictureProcessor from '../views/pictureProcessor.vue';
import storyProcessor from '../views/storyProcessor.vue';
import addMp3Time from '../views/addMp3Time.vue';
import editTime from '../views/editTime.vue';
import hnmTime from '../views/hnmTime.vue';
import teenTime from '../views/teenTime.vue';
import razProcessor from '../views/razProcessor.vue';
import hnmProcessor from '../views/hnmProcessor.vue';
import addRaz from '../views/addRaz.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { title: 'Home' },
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage,
    meta: { title: 'About' },
  },
  {
    path: '/TextProcessor',
    name: 'TextProcessor',
    component: TextProcessor,
    meta: { title: '新概念一' },
  },
  {
    path: '/TextProcessor2',
    name: 'TextProcessor2',
    component: TextProcessor2,
    meta: { title: '新概念二' },
  },
  {
    path: '/pictureProcessor',
    name: 'pictureProcessor',
    component: pictureProcessor,
    meta: { title: '图片绘本' },
  },
  {
    path: '/storyProcessor',
    name: 'storyProcessor',
    component: storyProcessor,
    meta: { title: '故事绘本' },
  },
  {
    path: '/addMp3Time',
    name: 'addMp3Time',
    component: addMp3Time,
    meta: { title: 'importMp3Time' },
  },
  {
    path: '/editTime',
    name: 'editTime',
    component: editTime,
    meta: { title: 'editTime' },
  },
  {
    path: '/hnmTime',
    name: 'hnmTime',
    component: hnmTime,
    meta: { title: 'hnmTime' },
  },
  {
    path: '/teenTime',
    name: 'teenTime',
    component: teenTime,
    meta: { title: 'teenTime' },
  },
  {
    path: '/razProcessor',
    name: 'razProcessor',
    component: razProcessor,
    meta: { title: 'importRAZ' },
  },
  {
    path: '/hnmProcessor',
    name: 'hnmProcessor',
    component: hnmProcessor,
    meta: { title: 'importHnm' },
  },
  {
    path: '/addRaz',
    name: 'addRaz',
    component: addRaz,
    meta: { title: 'addRaz' },
  },
  // 你可以继续添加更多路由
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  document.title = to.meta.title || '英语';
})


export default router;