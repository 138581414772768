<template>
    <div class="container">
      <!-- 新增的文本框及按钮 -->
      <div class="input-area-container">
        <input v-model="lessonNum" class="input-field" placeholder="Lesson Number">
        <textarea v-model="timeData" class="input-text" placeholder="Enter time ranges here (e.g., 33-88 88-99 99-222)" rows="10"></textarea>
        <button @click="addTime" class="action-button">Add Time</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // 在现有的数据模型基础上添加
        lessonNum: '', // 课程编号
        timeData: '', // 时间数据
      };
    },
    methods: {
      // 在 Vue 组件的 methods 部分添加
      addTime() {
  // 解析 timeData 字段，将其转换为所需的格式
  const timeRanges = this.timeData.split('\n').map(range => {
    const [start, end] = range.split('-').map(Number);
    return { lessonNum: this.lessonNum, startTime: start, endTime: end };
  });

  // 验证输入数据的有效性（可选）
  if (!this.lessonNum || timeRanges.some(range => isNaN(range.startTime) || isNaN(range.endTime))) {
    alert('Invalid input data. Please check the lesson number and time ranges.');
    return;
  }

  // 发送HTTP POST请求到 updateTime.php 接口
  fetch('https://chat.morecale.com/zhiwangen/updateTime.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(timeRanges),
  })
    .then(response => response.json())
    .then(data => {
      // 处理后端接口的响应
      console.log('Response from server:', data);
      if (data.success) { // 假设后端返回的数据中有个success标识操作是否成功
        alert('Time data updated successfully!');
        // 成功后lessonNum自增1
        this.lessonNum = String(Number(this.lessonNum) + 1);
        this.timeData = ''; // 清空输入框
      } else {
        // 可以根据实际情况处理失败的情况
        //alert('Update failed. Please try again.');
        alert(data.message);
      }
    })
    .catch(error => {
      // 处理请求过程中的错误
      console.error('Error:', error);
      alert('Error updating time data. Please try again.');
    });
},
    },
  };
  </script>
  
  <style scoped>
  /* 根据实际需要调整样式 */
  .input-area-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .input-field, .input-text {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-text {
    width: 600px; /* 调整宽度 */
    height: 200px; /* 调整高度 */
  }
  
  .action-button {
    width: 120px; /* 调整按钮宽度 */
    height: 40px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
/* 媒体查询 */
@media (max-width: 768px) { /* 针对小于768px宽的屏幕 */
  .input-text {
    width: 90%; /* 宽度调整为父容器宽度的90% */
    height: 150px; /* 高度适当调整 */
  }
}

@media (max-width: 480px) { /* 针对小于480px宽的屏幕 */
  .input-text {
    width: 90%; /* 宽度进一步调整为父容器宽度的90% */
    height: 100px; /* 高度进一步调整 */
  }
}


  </style>