<template>  
  <div class="containerNo">
    <!-- <div ><h4>导入绘本资源</h4></div> -->
    <div class="subject"><h4>Import PictureBook Resource-Bat/The Big/Wieard</h4></div>
    <div class="text-area-container">
      <textarea v-model="inputText" class="input-text" placeholder="Enter text here" rows="20"></textarea>
      <div class="button-group">
        <!-- <button @click="pasteInput" class="action-button">Paste</button> -->
        <button @click="processText" class="action-button">Process</button>
        <button @click="writeToExcel" class="action-button">Write to Excel</button>
      </div>
    </div>
    <div class="text-area-container">
      <textarea v-model="outputText" class="output-text" placeholder="Processed text will appear here" readonly rows="20"></textarea>
      <!-- <button @click="copyOutput" class="action-button">Copy</button> -->
    </div>
  </div>

  
<!-- 新增的文本框及字段 -->
<div class="text-area-container">
  <div class="input-container">
    <input v-model="lessonNum" @input="setTextAudioUrl" class="input-field" placeholder="Lesson Number">
    <input v-model="subject" class="input-field" placeholder="Subject">
    <input v-model="lessonID" class="input-field" placeholder="Lesson ID">    
    <input v-model="audio_url" @input="setTextPicFromAudioUrl" class="input-field" placeholder="Audio URL">        
    <input v-model="text_pic" class="input-field" placeholder="Text Picture">
    <input v-model="kind_desc" class="input-field" placeholder="kind desc">
  </div>
  <button @click="writeToDatabase" class="action-button">Write to Database</button>
</div>

<!-- <div>{{ text_pic }}</div> -->
</template>


<script>
// 引入 xlsx 库，用于 Excel 操作
import * as XLSX from 'xlsx';
// 引入 MySQL 模块
//import mysql from 'mysql';

export default {
  data() {
    return {
      //inputText: '', // 用户输入的文本
      //outputText: '', // 处理后的文本
      uploadFileurl:'',//看是哪一个目录 通过1 2 3 选择
      inputText: '', // 用户输入的文本
      outputText: '', // 处理后的文本
      lessonNum: '', // 课程编号
      subject: '', // 科目
      lessonID: '1', // 课程ID
      audio_url: '', // 音频链接
      text_pic: '', // 文本图片链接
      kind_desc:'2',//哪一本绘本 绘本 1:Bat and Friends 2:The big Green Forest 3:Wizard and Cat
    };
  },
  watch: {
  lessonNum() {
    // 在 lessonNum 发生变化时执行相应的操作
    //this.updateTextFields(newLessonNum);
    this.setTextAudioUrl();
  },
  audio_url() {
      // 在 lessonNum 发生变化时执行相应的操作
      //this.updateTextFields(newLessonNum);
      this.setTextAudioUrl();
    }
},
  methods: {
    // 当用户填写audio_url时，自动设置text_pic为相同的值
  
  setTextPicFromAudioUrl() {
  console.log('setTextPicFromAudioUrl called');
  this.text_pic = this.audio_url;
},
oldsetTextAudioUrl() {
  // console.log('setTextPicFromAudioUrl called');
  if(this.lessonNum<10)
  {
    //this.audio_url = 'lesson0' + this.lessonNum;
    this.text_pic = '00' + this.lessonNum + '-';
  }else
  if(this.lessonNum<100)
  {
    //this.audio_url = 'lesson' + this.lessonNum;
    this.text_pic = '0' + this.lessonNum + '-';
  }else{
    //this.audio_url = 'lesson' + this.lessonNum;
    this.text_pic = '' + this.lessonNum + '-';
  }
},

setTextAudioUrl() {    
    if (!this.audio_url || this.audio_url.length < 1) return;
    this.text_pic = this.audio_url.substring(0, 3) + '-';
  },


 // 在您的组件中的方法部分添加以下代码：
 // 处理并生成要传递的JSON数据
generateJSONData() {
  //根据kind_desc的值选择要上传的目录地址  
  if(this.kind_desc==='1') {
    this.uploadFileurl="BatandFriends";
  }
  if(this.kind_desc==='2') {
    this.uploadFileurl="TheBigGreenForest";
  }
  if(this.kind_desc==='3') {
    this.uploadFileurl="wizardandcat";
  }

  const paragraphs = this.outputText.split('\n\n');
  const jsonData = [];

  // 遍历每个段落生成JSON数据
  paragraphs.forEach((content, index) => {
    const lessonID = index + 1; // lessonID按照段落序号生成
  // 检查 this.index + 1 的值，如果小于10，则在数字前补一个0
  const text_pic_new = this.text_pic + ((lessonID < 10) ? '0'+ (lessonID) : + (lessonID)) ;
    const dataObject = {
      lessonNum: this.lessonNum,
      subject: this.subject,
      lessonID: lessonID.toString(), // 转为字符串形式
      //audio_url: 'zhiwangen/CartoonBook/CartoonMP3/BatandFriendsmp3/' + this.audio_url + '.mp3', // 拼接audio_url
      audio_url: 'zhiwangen/CartoonBook/CartoonMP3/' + this.uploadFileurl + 'mp3/' + this.audio_url + '.wav', // 拼接audio_url
      //text_pic: 'zhiwangen/CartoonBook/CartoonPIC/BatandFriendsPic/' + this.text_pic + '.jpg', // 拼接text_pic
      //text_pic: 'zhiwangen/CartoonBook/CartoonPIC/BatandFriendsPic/' + text_pic_new + '.jpg', // 拼接text_pic
      text_pic: 'zhiwangen/CartoonBook/CartoonPIC/' + this.uploadFileurl + 'Pic/' + text_pic_new + '.jpg', // 拼接text_pic
      startTime: '1.0', // 设置默认startTime
      endTime: '200.0', // 设置默认endTime
      content: content.trim(), // 去除段落前后空白
      kind_desc:this.kind_desc,
    };    
    jsonData.push(dataObject);
  });

  return jsonData;
},

// 在写入数据库的方法中调用生成JSON数据的方法，并发送HTTP请求到后端接口
writeToDatabase() {
  // 检查五个文本框是否有值
  if (!this.lessonNum || !this.subject || !this.audio_url || !this.text_pic || !this.inputText) {
    alert('Please fill in all required fields!');
    return;
  }
  const jsonData = this.generateJSONData();
  // 保存原始 lessonNum 的值
  const originalLessonNum = this.lessonNum;

  // 发送HTTP POST请求到后端接口
  fetch('https://chat.morecale.com/zhiwangen/importpicture.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(jsonData),
  })
    .then(response => response.json())
    .then(data => {
      // 处理后端接口的响应
      console.log('Response from server:', data);
      alert('Data written to database successfully!');
       // 更新 lessonNum 的值为原始值加2
       //this.lessonNum = originalLessonNum + 2;
       this.lessonNum = parseInt(originalLessonNum) + 1;
      
       //清空文本框
       this.inputText='';
       this.outputText='';
       this.subject='';
    })
    .catch(error => {
      // 处理错误
      console.error('Error:', error);
      alert('Error writing data to database. Please try again.');
    });
},
   


processTextBAK1() {
  // 处理逻辑
  const lines = this.inputText.split('\n');
  let subject = ''; // 初始化subject为空
  let additionalProcessingNeeded = false; // 附加处理标志

  // 提取第3行中的标题内容作为subject
  if (lines.length >= 3 && lines[2].startsWith('[ti:')) {
    subject = lines[2].substring(lines[2].indexOf(':') + 1, lines[2].lastIndexOf(']')).trim();
    this.subject = subject; // 将提取的subject放入subject文本框
  }

  // 过滤并处理剩余文本行
  const filteredAndProcessedLines = lines
    .filter((line, index) => index !== 0 && index !== 1 && index !== 3 && index !== 4)
    .map(line => {
      // 确认是否有附加文本处理的需求
      if (!additionalProcessingNeeded && !line.startsWith('[')) {
        additionalProcessingNeeded = true;
      }
      // 移除时间戳并确保即使行为空也不再附加新行
      return (line.startsWith('[ti:')) ? '' : line.replace(/\[\d+:\d+\.\d+\]/, '').trim();
    });

  // 如果确实有需要处理的额外文本，确保第一行之前不添加多余的换行符
  this.outputText = (additionalProcessingNeeded ? filteredAndProcessedLines.join('\n') : filteredAndProcessedLines.slice(1).join('\n')).trim();
},
processText() {
  // 按行分割输入文本
  const lines = this.inputText.split('\n');
  
  // 提取subject，即第一行文本
  const subject = lines[0];
  this.subject = subject; // 将提取的subject放入subject文本框

  // 提取audio_url的一部分，即第二行文本，并进行拼接
  const audioUrlPart = lines[1];
  //const audioUrl = `zhiwangen/CartoonBook/CartoonMP3/${audioUrlPart}.mp3`;
  this.audio_url = audioUrlPart; // 将拼接后的audioUrl放入相应文本框
   
  // 从第三行开始处理剩余文本。理论上第三行是一个空行，所以实际故事文本从第四行开始
  let storyText = lines.slice(3).join('\n').trim(); // 移除首尾空白字符
  
  // 处理故事文本中的空行分隔，确保故事文本正确分段
  storyText = storyText
    .split('\n\n') // 按空行分割故事文本
    .filter(paragraph => paragraph.trim() !== '') // 移除空段落
    .join('\n\n'); // 重新连接段落，使用两个换行符作为段落之间的分隔

  // 更新输出文本
  this.outputText = storyText;

  // 如果需要，这里可以添加进一步的逻辑处理，如特殊标记处理等
},

    pasteInput() {
      this.readTextFromClipboard();
    },
    copyOutput() {
      if (this.outputText.trim() !== '') {
        this.copyTextToClipboard(this.outputText);
      }
      if (this.outputText.trim() === '' && this.inputText.trim() !== '') {
        alert('No processed text to copy!');
      }
    },
    readTextFromClipboard() {
      const textarea = document.createElement('textarea');
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.focus();
      document.execCommand('paste');
      this.inputText = textarea.value;
      document.body.removeChild(textarea);
    },
    copyTextToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    },
    writeToExcel() {
      // 创建一个工作簿
      const wb = XLSX.utils.book_new();
      // 创建一个工作表
      const ws = XLSX.utils.aoa_to_sheet([
        ['序号', '内容'],
        ...this.outputText.split('\n\n').map((content, index) => [index + 1, content.trim()]),
      ]);
      // 将工作表添加到工作簿
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      // 将工作簿保存为 Excel 文件
      XLSX.writeFile(wb, 'D:/abc.xls');
      alert('Data written to Excel successfully!');
    },
  },
};
</script>

<style scoped>
.container {
  margin-top:20px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
}
.subject{
  text-align: center;
}

.text-area-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.input-text, .output-text {
  width: 1200px;
  height: 550px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom:10px;
}

.button-group {
  margin-top: 10px;
}

.action-button {
  margin-top:10px;
  margin-right: 10px;
  width: 120px; /* 调整按钮宽度 */
  height: 40px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #0056b3;
}



</style>