<template>
  <div>
    <h1>About Page</h1>
    <p>This is the About Page of our website.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>