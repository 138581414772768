<!-- 要修改3个PHP文件 gettime querycartoon upaudiotime-->
<template>
    <div class="container">
      <div class="top-section">     
        <!-- 下拉选择框 -->
        <span>Choose Subject: </span>        
            <select v-model="selectedSubject">
            <option v-for="item in uniqueCartoonData" :value="item.subject" :key="item.lessonNum">
                {{ item.subject }}
            </option>
            </select>
        <!-- <button class="playbutton" @click="fetchTimeData">GetAudio</button> -->
      </div>
      <div class="modal" v-if="isDataLoading">  查询中...</div>
      <div class="middle-section">        
        <div v-if="timeData && timeData.status === 'success'">
          <ul>
            <li v-for="(item, index) in timeData.data" :key="index">              
<div class="contenttime">
  <div class="text-content">
    <strong>{{ index + 1 }}</strong> {{ item.content }}
  </div>
  <div class="time-inputs">
    startTime：<input type="number" v-model="item.startTime" @input="updateTimes(item)"  @dblclick="fillStartTime(item)"/>
    endTime：<input type="number" v-model="item.endTime" @input="updateTimes(item)" @dblclick="fillEndTime(item)" />
    <!-- endTime：<input type="number" v-model="item.endTime" @dblclick="fillEndTime" /> -->
  </div>
</div>
              <!-- 这里可以添加更多您想展示的信息 -->
                    <div>
                        

                        <audio :src="getFullAudioUrl(item.audio_url)" type="audio/wav" @loadedmetadata="handleLoadedMetadata($event, item)" @timeupdate="handleTimeUpdate($event, item)"></audio>
                          
                        <div class="time-display-container">
                            <div class="currenttime" v-if="currentPlaying === item">
                                Current Time: {{ currentPlayingTime ? currentPlayingTime.toFixed(1) : '0' }} seconds
                            </div>
                            </div>


                    </div>
                    <div class="contentbutton">
                    <div class="buttoncss">
                    <button @click="playAudio(item)" class="playbutton">Play</button>
                    
                    <!-- <button @click="stopCurrentAudio(item)" class="stopbutton">Stop</button> -->
                    <!-- <button @click="stopCurrentAudio(item)" class="stopbutton" :class="{ 'red-background': currentAudio && !currentAudio.paused && currentPlaying === item }">
                      {{ currentAudio && !currentAudio.paused && currentPlaying === item ? 'Stop' : 'Pause' }}
                    </button> -->

         
<!-- <button @click="toggleAudio(item)" class="stopbutton" :class="{ 'red-background': isPlaying(item) }"> 
    {{ isPlaying(item) ? 'Stop' : 'Pause' }}
</button> -->

<button @click="toggleAudio(item,index)" class="stopbutton" :class="{ 'red-background': isPlaying(item) }"> 
    {{ isPlaying(item) ? 'Stop' : 'Pause' }}
</button>


                    <button @click="updateCurrentAudio(item)" class="stopbutton">Update</button>
                    </div></div>

            </li>
          </ul>
        </div>
        <p v-else-if="timeData && timeData.status === 'error'">{{ timeData.message }}</p>
      </div>
      <!-- <div class="bottom-section"> -->
        <!-- 其他内容 -->
          <!-- 添加更新全部按钮 -->
  <!-- <button @click="updateAllTimes" class="update-button">Update All</button> -->
  <div class="bottom-section" v-if="timeData && timeData.status === 'success'">
    <!-- 其他内容 -->
    <!-- 添加更新全部按钮 -->
    <button @click="updateAllTimes" class="updateall">Update All</button>
 </div>

      <!-- </div> -->
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isAudioPlaying: false, // 控制音频播放状态
        isDataLoading: true, // 控制模态提示的显示
        previousEndTime: '', // 上一条记录的结束时间
         lessonID:0,
         startTime:0,
         endTime:0,
        audio_url:'',
        cartoonData: [], // 用于存储从后端获取的cartoon数据
        selectedSubject: '', // 用户选择的subject
        currentPlayingTime: 0, // 确保这是一个数字
        showStart:0,//从第二个开始显示的时间
        showEnd:0,//从第二个开始显示的时间    
        boundHandleTimeUpdate: null, // 添加这个属性来存储绑定了上下文的方法
        currentTimeFormatted: '00:00', // 初始值为00:00
        currentPlaying: null, // 新增：用于标识当前播放的音频项
        kind_desc:'',
        currentAudio: null, // 用于存储当前播放的Audio对象
        //currentPlaying: '', // 添加这一行
        lessonNum: 0, // 绑定到输入框的数据
        //timeData: null, // 用于存储从后端获取的时间信息        
        timeData:[],
        //lessonInfoArray:[],
      currentTime: 0,
      thiscurrectTime:0,
      totalTime: 0,
      isAudioLoaded: false // 添加一个标志来表示音频是否加载完成
      };
    },
    watch: {
  selectedSubject(newVal) {
    // 当选择的subject改变时，更新对应的lessonID
    //console.log('选择了的选项 ');
    console.log(newVal);
    //console.log(oldVal);
    
    const selectedData = this.cartoonData.find(item => item.subject === newVal);
    if (selectedData) {
      //this.selectedLessonID = selectedData.lessonID;
      this.lessonNum = selectedData.lessonNum;
      console.log(this.lessonNum);
      //this.bakfetchTimeData();
      this.bakstopCurrentAudio() ;
      this.fetchTimeData();
    }
  }

},
    computed: {
        uniqueCartoonData() {
    const uniqueSubjects = new Set(this.cartoonData.map(item => item.subject));
    return Array.from(uniqueSubjects).map(subject => {
      // 对于每个唯一的subject，找到第一个匹配的cartoonData条目并返回
      return this.cartoonData.find(item => item.subject === subject);
    });
  },
        isLessonNumValid() {
    return this.lessonNum >= 11 && this.lessonNum <= 20;
  },
        currentTimeFormattedA() {
    // 检查是否有正在播放的音频且当前播放时间存在
    if (this.currentPlaying && this.currentPlaying.currentTime >= 0) {
      const time = this.currentPlaying.currentTime;      
      // 格式化时间，例如：mm:ss
      console.log('这里显示按钮点击后的时间');
      console.log(this.currentPlaying.currentTime);
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
    return '0:00'; // 如果没有正在播放的音频，返回默认值
  }
  },
  created() {
    //this.fetchCartoonData();
    
  // 如果还需要执行原有的绑定操作    
    // 绑定 handleTimeUpdate 方法到这个组件实例，确保它在回调中具有正确的this上下文    
    this.boundHandleTimeUpdate = this.handleTimeUpdate.bind(this);
  },
 mounted() {
    this.fetchCartoonData();
  // 假设 fetchCartoonData() 是异步的
  this.$nextTick(() => {
    if (this.uniqueCartoonData.length > 0) {
      this.selectedSubject = this.uniqueCartoonData[0].subject;
      // 初始化对应的lessonID
      this.selectedLessonID = this.uniqueCartoonData[0].lessonID;
    }
  });


},
  
    methods: { 
      //按暂停后，自动填入时间的方法
      fillEndTimeAutomatically(item,index) {
        item.endTime = this.currentPlayingTime ? this.currentPlayingTime.toFixed(1) : item.endTime;
        this.previousEndTime = item.endTime;
        this.fillNextStartTime(index);
    },
    fillNextStartTime(currentIndex) {
        // 获取下一条记录
        const nextItem = this.timeData.data[currentIndex + 1];
        if (nextItem) {
            nextItem.startTime = (parseFloat(this.previousEndTime) + 1).toFixed(1);
        }
    },
      toggleAudio(item,index) {
        if (!this.isAudioPlaying) {
            return; // 如果音频尚未播放，则直接返回，不执行后续逻辑
        }
    if (!this.currentAudio) {
        this.playAudio(item);
    } else if (this.currentPlaying === item) {
        if (this.currentAudio.paused) {
            this.currentAudio.play();
        } else {
            this.currentAudio.pause();
            //这里加上方法：如果按了暂停，就将时间写入到endTime里。
            this.fillEndTimeAutomatically(item,index);
        }
    } else {
        this.playAudio(item);
    }
},

isPlaying(item) {
    return this.currentAudio && !this.currentAudio.paused && this.currentPlaying === item;
},
      // 更新全部的功能
     // 更新全部的功能
     async updateAllTimes() {
    // 创建一个空数组来存储所需信息
    let lessonInfoArray = [];

    // 遍历timeData.data数组
    this.timeData.data.forEach(item => {
        // 从每个课时对象中获取lessonNum、lessonID、startTime和endTime
        let lessonNum = item.lessonNum;
        let lessonID = item.lessonID;
        let startTime = item.startTime;
        let endTime = item.endTime;
        
        // 创建一个对象来存储当前课时的信息
        let lessonInfo = {
            lessonNum: lessonNum,
            lessonID: lessonID,
            startTime: startTime,
            endTime: endTime
        };

        // 将当前课时的信息添加到lessonInfoArray数组中
        lessonInfoArray.push(lessonInfo);
    });

  try {
    console.log("开始显示timedata");
    console.log(this.timeData.data);
    console.log("显示thistimedta数据");

    const url = 'https://chat.morecale.com/zhiwangen/updateAllAudioTimes.php'; // 替换为你的后端接口URL

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      //body: JSON.stringify({ timeData: this.timeData }),
      body: JSON.stringify({ timeData: lessonInfoArray }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();

    if (responseData.status === 'error') {
      // 如果后端返回错误状态，显示错误消息
      console.error('Error updating lesson times:', responseData.message);
      alert('Failed to update lesson times: ' + responseData.message);
    } else {
      // 如果后端返回成功状态，显示成功消息
      alert('Update successful!');
      console.log(responseData);
    }
  } catch (error) {
    // 捕获网络请求失败等其他错误
    console.error('Error updating lesson times:', error);
    alert('Failed to update lesson times.');
  }
},
  
      // 双击填充开始时间
    fillStartTime(item) {      
        // 将上一条记录的结束时间填入当前记录的开始时间
        //this.item[index].startTime = this.previousEndTime;
        if(this.previousEndTime){
        item.startTime=(parseFloat(this.previousEndTime)+1).toFixed(1) ;      
        this.previousEndTime=''; //清空
      }else{
        return ;
      }
    },
      fillEndTime(item) {
      // item.endTime = this.currentPlayingTime ? this.currentPlayingTime.toFixed(1) : '0';
      item.endTime = this.currentPlayingTime ? this.currentPlayingTime.toFixed(1) : item.endTime;
      this.previousEndTime=item.endTime;
        // 在双击事件中显示弹窗        
      //window.alert("Current Time: ");
    },

        async updateCurrentAudio(item) {
  // 将字符串转换为数字
    //const startTime = Number(item.startTime);
    //const endTime = Number(item.endTime);
  // 验证转换后的startTime和endTime
  //if (isNaN(startTime) || isNaN(endTime)) {
  //  alert("StartTime and EndTime should be numeric.");
  //  return;
 // }

  try {
    const url = 'https://chat.morecale.com/zhiwangen/updateAudioTime.php'; // 替换为你的后端接口URL
    const body = {
      lessonNum: item.lessonNum,
      lessonID: item.lessonID,
      startTime: item.startTime,
      endTime: item.endTime
    };
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const responseData = await response.json();
    alert('Update successful!');
    console.log(responseData);
  } catch (error) {
    console.error('Error updating lesson time:', error);
    alert('Failed to update lesson time.');
  }
},
        

        updateLessonNum() {
    const selectedData = this.cartoonData.find(item => item.subject === this.selectedSubject);
    if (selectedData) {
      this.lessonNum = selectedData.lessonNum;
    } else {
      console.error("Selected subject does not match any data.");
    }
  },
        //载入查询
        async fetchCartoonData() {
            this.isDataLoading = true; // 数据开始加载，显示模态提示

            this.kind_desc='1';  //这是1-6 cartoon 绘本            
            try {
                const url = 'https://chat.morecale.com/zhiwangen/queryCartoon.php';
                const params = new URLSearchParams({
                kind_desc: this.kind_desc // 假设kind_desc是需要的查询参数 是哪一本绘本
                });
                const response = await fetch(`${url}?${params}`, {
                method: 'GET'
                });
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                const data = await response.json();
                // 假设后端返回的数据结构是 { status: 'success', data: [/* 数据数组 */] }
                if (data.status === 'success') {
                // 更新数据到响应式属性中，比如this.cartoonData
                this.cartoonData = data.data;
                } else {
                // 处理错误情况
                console.error('Failed to fetch cartoon data:', data.message);
                }
            } catch (error) {
                console.error('Error fetching cartoon data:', error);
            }
            this.isDataLoading = false; // 数据加载完成，隐藏模态提示

            // 确保数据加载完毕后设置默认选中项
                // if (this.cartoonData.length > 0) {
                //     this.$nextTick(() => {
                //     this.selectedSubject = this.uniqueCartoonData[0].subject;
                //     this.selectedLessonID = this.uniqueCartoonData[0].lessonID;
                //     });
                // }
},
//获得TIME数据     
    async fetchTimeData() {
        this.bakstopCurrentAudio();
        //this.updateLessonNum();   
        //this.lessonNum = await this.getLessonNumFromSubject(this.selectedSubject);     
        console.log(this.lessonNum);
        //debugger; // 代码执行到这里时会暂停，如果开发者工具是打开的

    // 根据selectedSubject找到对应的lessonNum
    // if (!this.cartoonData) { // 假设 audioUrl 是绑定到 audio_url 数据的变量
    //   // 可以在这里显示一个加载指示，或者直接返回等待数据加载
    //   alert("Audio data is loading. Please try again.");
    //   return;
    // }
    //const selectedData = this.cartoonData.find(item => item.subject === this.selectedSubject);
    //this.lessonNum = selectedData ? selectedData.lessonNum : null;

        try {
            const url = `https://chat.morecale.com/zhiwangen/getTime.php?lessonNum=${encodeURIComponent(this.lessonNum)}`;
            const response = await fetch(url);
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const data = await response.json();
            //console.log(data);
            // 假设你的API返回的数据中包含了音频文件的路径，并且你希望自动播放第一个音频文件
                if (data.data && data.data.length > 0) {
                    // const firstAudioPath = data.data[0].audio_url; // 确保这是正确的属性名
                    const firstAudioPath = data.data[0].audio_url; // 确保这是正确的属性名
                    const fullAudioUrl = this.getFullAudioUrl(firstAudioPath);
                    this.playAudio(fullAudioUrl);  //虽然报错，但是解决了无法查询的问题
                }

            // 扩展每个音频项，添加状态属性
            const updatedData = data.data.map(item => ({
            ...item,
            currentTime: 0,
            totalTime: 0,            
            currentTimeFormatted: '00:00',
            }));
            this.timeData = { ...data, data: updatedData };           
            
        } catch (error) {
            console.error('Error fetching time data:', error);
            this.timeData = { status: 'error', message: 'Failed to fetch data' };
        }
        
},        
        getFullAudioUrl(audioPath) {
      return `https://www.zhiwangjiaoyu.com/${audioPath}`;
    },

 // 更新时间的方法
 updateCurrentTime() {
    if (!this.currentAudio) return;

    const currentTime = this.currentAudio.currentTime;
    this.currentTimeFormatted = this.formatTime(currentTime);
  },

  // 更新时间的方法2
 updateCurrentTime2() {
    if (!this.currentAudio) return;

    const currentTime = this.currentAudio.currentTime;
    this.currentTimeFormatted = this.formatTime(currentTime);
  },

  // 将时间（秒）格式化为MM:SS格式
  formatTime(seconds) {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
  },


    stopAudio(item) {
  const audioPlayer = this.$refs[`audioPlayer-${item.audio_url}`][0];
  if (audioPlayer) {
    audioPlayer.pause();
    audioPlayer.currentTime = 0; // 可选：重置音频时间到开始，以便下次播放从头开始
  }
},
    stopAllAudio() {
    // 遍历所有的audio元素的refs并停止播放
    for (const refName in this.$refs) {
        if (refName.startsWith('audioPlayer-')) {
        this.$refs[refName][0].pause();
        this.$refs[refName][0].currentTime = 0; // 可选：重置音频时间到开始
        }
    }
    },
    

handleLoadedMetadata(event, item) {
  // 更新特定音频项的总时间
  const index = this.timeData.data.findIndex(i => i.audio_url === item.audio_url);
  if (index !== -1) {
    this.timeData.data[index].totalTime = event.target.duration;
  }
  item.totalTime = event.target.duration;

},


playAudio(item) {    
  // 播放音频的相关逻辑
    this.isAudioPlaying = true;
    console.log(item);
    console.log(item.startTime);
    console.log(item.endTime);
    console.log(item.audio_url);
    this.showStart=item.startTime;
    this.showEnd=item.endTime;
  // 停止当前播放的音频并移除监听器
  if (this.currentAudio) {
    this.currentAudio.pause();
    //this.currentAudio.removeEventListener('timeupdate', this.handleTimeUpdate);
    //this.currentAudio.removeEventListener('timeupdate', this.boundHandleTimeUpdate);

  }

  const audioUrl = this.getFullAudioUrl(item.audio_url); // 假设这个方法用于获取完整的音频 URL
  const audio = new Audio(audioUrl);
  audio.currentTime = item.startTime || 0; // 如果定义了开始时间，则从该时间开始播放

  // 更新当前播放的音频对象和当前播放项
  this.currentAudio = audio;
  this.currentPlaying = item;
  //this.currentPlaying = audioUrl; // 标记当前播放的音频URL
  // 添加事件监听器到新的音频对象
  //this.currentAudio.addEventListener('timeupdate', this.boundHandleTimeUpdate);
  // 当音频元数据加载完成后，更新音频的总时长
  audio.addEventListener('loadedmetadata', () => {
    const index = this.timeData.data.findIndex(i => i.audio_url === item.audio_url);
    if (index !== -1) {
      // 直接赋值来更新Vue 3的响应式数据
      this.timeData.data[index].totalTime = audio.duration;
    }
  });

  // 为当前音频添加时间更新的监听器
   audio.addEventListener('timeupdate', this.handleTimeUpdate);
   audio.play().catch(error => {
     console.error("Error playing audio:", error);    
   });
},

  handleTimeUpdate(event) {  
  console.log('更新时间上面的数据');
  this.$forceUpdate(); // 如果需要的话，强制 Vue 重新渲染
  //if (!this.currentPlaying.audio_url) {
  //  console.error('Audio data is not ready');
  // return; // 数据未就绪，直接返回避免进一步的错误
 // }
//-0.2
  this.currentPlayingTime = Number(event.target.currentTime) || 0;
  const currentTime = event.target.currentTime;
  const index = this.timeData.data.findIndex(i => i.audio_url === this.currentPlaying.audio_url);
  if (index !== -1) {
    this.timeData.data[index].currentTime = currentTime;
    this.timeData.data[index].currentTimeFormatted = this.formatTime(currentTime);

    if (this.currentPlaying.endTime && currentTime >= this.currentPlaying.endTime) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
      this.currentAudio.removeEventListener('timeupdate', this.handleTimeUpdate);
    }
  }
},

  // formatTime 方法和其他必要的组件方法...






updateTimes(item) {
    // 这里可以添加更新时间后需要执行的逻辑，比如验证
    console.log(`更新后的开始时间: ${item.startTime}, 结束时间: ${item.endTime}`);
    //console.log(this.timeData);
  },
bakstopCurrentAudio() {
  if (this.currentAudio) {
    this.currentAudio.pause();
    //this.currentAudio.currentTime = 0; // 可选：重置音频时间到开始
    //this.currentAudio = null; // 清除引用
  }
},
stopCurrentAudio(item) {
    if (this.currentAudio && !this.currentAudio.paused && this.currentPlaying === item) {
        // 如果当前音频正在播放，并且与点击的项匹配，则暂停音频
        this.currentAudio.pause();
    } else if (this.currentAudio && this.currentAudio.paused && this.currentPlaying === item) {
        // 如果当前音频已暂停，并且与点击的项匹配，则继续播放音频
        this.currentAudio.play();
    }
},
            
    }
  };
  </script>
  
  <style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}
.content{
    text-align: left;
}

.top-section, .middle-section, .bottom-section {
  padding: 20px;
  text-align: center;
}

input[type="number"] {
  margin-right: 10px;
}

ul {
  list-style-type: none; /* 移除列表项前的小点 */
  padding: 0; /* 移除默认的内边距 */
}
.playbutton{
   margin-left:30px;
   margin-right:10px;
   margin-top:15px;
   padding:5px 15px 5px 15px;
}
.stopbutton{
   margin-left:28px;
   margin-right:10px;
   padding:5px 15px 5px 15px;
   
}
.contenttime {
  display: flex;
  justify-content: space-between; /* 保持内容和输入框两端对齐 */
  align-items: center; /* 垂直居中 */
}


.text-content {
  max-width: 800px; /* 或您希望的任何最大宽度 */
  word-wrap: break-word;
   /* 保证在达到最大宽度时单词可以被拆分换行 */
   text-align: left;
   padding-right:20px;
}

.time-inputs {
  display: flex;
  gap: 10px; /* 输入框之间的间隙 */
}

.time-inputs input {
  /* 根据需要调整输入框的样式，例如宽度、边框等 */
  width:60px;
}
.currenttime{
    color:#057c85;
}
.contentbutton {
  display: flex; /* 启用 flex 布局 */
  width: 100%; /* 确保它占满可用宽度 */
  margin-bottom: 15px;
}

.buttoncss {
  margin-left: auto; /* 将按钮组推向右边 */
}
.time-display-container {
  display: flex; /* 启用 flexbox */
  justify-content: flex-end; /* 使内容对齐到容器的末端（右侧） */
  margin:10px 15px 0px 0px;
}
/* 下拉选择框的样式 */
select {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  outline: none;
  font-size: 15px;
}

/* 选项样式 */
option {
  padding: 10px;
}
.modal {
  position: fixed; /* 或absolute，根据需求 */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,0.5);
  color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 1000; /* 确保它位于其他内容之上 */
}
.updateall{
  padding:10px 20px 10px 20px;
  margin-bottom: 20px;
}
.red-background {
    background-color:#4CAF50;
    border-color: #4CAF50; /* 边框线颜色与背景色相同 */
    color:#ffffff;
}


/* 现有样式 */
/* 添加的媒体查询 */
@media (max-width: 768px) {
  .contenttime {
    flex-direction: column; /* 在移动设备上将主轴方向改为垂直 */
    align-items: flex-start; /* 对齐到起始边 */
    padding-left: 10px;
  }

  .text-content {
    margin-bottom: 10px; /* 添加一些间距，使得内容和输入框之间不会太紧凑 */
    padding-left:10px;
  }

  .time-inputs {
    justify-content: space-between; /* 确保输入框在容器中平均分布 */
    width: 100%; /* 输入框容器占满全部宽度 */
  }

  .buttoncss {
    justify-content: center; /* 在移动端将按钮居中显示 */
    width: 100%; /* 确保按钮组占满可用宽度 */
    margin-top: 10px; /* 在按钮组和上方内容之间添加一些间距 */
  }

  .playbutton, .stopbutton {
    margin: 5px 10px; /* 调整按钮间距和边距以更好地适应移动屏幕 */
  }
}

</style>